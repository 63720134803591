import React, { lazy, Suspense } from 'react'
import { appConfig, targetEnv, geoData } from '@fs/zion-config'
import { BrightspotRouting } from '@fs/zion-cms'
import zionDebug from '@fs/zion-debug'
import ErrorBoundary from '@fs/zion-error-boundary'
import { useFeatureFlag } from '@fs/zion-flags'
import { NoticeLoading } from '@fs/zion-icon'
import { i18n } from '@fs/zion-locale'
import { AuthRoute, Switch, Route, NotFound } from '@fs/zion-router'
import Head from '@fs/zion-head'
import NLIHPS from './NLIHPS.json'
import { ZION_CMS_SITES } from './constants'

import {
  famousPeoplePendingApprovalRoutes,
  famousPeopleRoutes,
} from './components/FamousPersonPage/shared/utils/famousPeople'

const AffiliatesAboutPage = lazy(() => import('./components/affiliates/about/AboutPage'))
const AffiliateHomePage = lazy(() => import('./components/affiliates/home/AffiliateHomePage'))
const MediaBanners = lazy(() => import('@fs/zion-media-banners'))

const UnitedKingdomPage = lazy(() => import('./components/nlihp/united-kingdom/UnitedKingdomPage'))
const UnitedStatesPage = lazy(() => import('./components/nlihp/united-states/UnitedStatesPage'))
const CanadaPage = lazy(() => import('./components/nlihp/canada/CanadaPage'))
const DeutschlandPage = lazy(() => import('./components/nlihp/deutschland/DeutschlandPage'))
const PortugalPage = lazy(() => import('./components/nlihp/portugal/PortugalPage'))

// Remove these pages once there is an acceptable amount of low traffic to them
const FamousPersonPage = lazy(() => import('./components/FamousPersonPage'))
const BookfairPage = lazy(() => import('./components/bookfair/BookfairPage'))
const OlympicsPage = lazy(() => import('./components/Olympics/OlympicsPage'))
const EventsPage = lazy(() => import('./components/Olympics/EventsPage/EventsPage'))

const debug = zionDebug('euronaPages:App')

// Each of the following languages must be a string containing a 2-char
// ISO language code (all lower case) followed by a hyphen and a 2-char
// ISO country code (all upper case) and must correspond to a folder in
// src/locales containing a translation.json. For example, a language of
// "pt-PT" (the Portuguese language in Portugal) must have a file at
// src/locales/pt-PT/translation.json.
const SUPPORTED_EXTENDED_LANGUAGES = ['pt-PT']

/**
 * If the selected language for this app does not have a country code, this will
 * look for a supported extended language that matches the user's country or the
 * country in the browser language setting. This function must be invoked in a
 * rendered component like App() to ensure that the i18n object is initialized.
 * Translations from the base language (without the country code) that are not
 * found in the extended language are added to the extended language.
 */
function handleExtendedLanguage() {
  if (!i18n.language.includes('-')) {
    const languageCode = i18n.language
    const browserLanguageParts = window?.navigator?.language?.split('-') || []
    const browserCountryCode = browserLanguageParts.length === 2 ? browserLanguageParts[1] : ''
    const userCountryCode = geoData?.country || ''
    let language = ''
    if (SUPPORTED_EXTENDED_LANGUAGES.includes(`${i18n.language}-${userCountryCode}`)) {
      language = `${languageCode}-${userCountryCode}`
    } else if (SUPPORTED_EXTENDED_LANGUAGES.includes(`${languageCode}-${browserCountryCode}`)) {
      language = `${languageCode}-${browserCountryCode}`
    }
    if (language) {
      if (!i18n.options.supportedLngs.includes(language)) {
        i18n.options.supportedLngs.push(language)
      }
      i18n.options.lowerCaseLng = false
      i18n.changeLanguage(language).then(() => {
        const resource = i18n.getResourceBundle(languageCode, 'translation')
        // This does not overwrite the existing language resource, but adds to it.
        i18n.addResourceBundle(language, 'translation', resource, true, false)
      })
    }
  }
}

function App({ site }) {
  handleExtendedLanguage()
  const showPendingApprovalFamousPeople = useFeatureFlag('Lynx_RelativesAtEvents_ShowPendingApprovalFamousPeople')?.isOn
  const activeFamousPeopleRoutes = famousPeopleRoutes
  if (showPendingApprovalFamousPeople) famousPeopleRoutes.push(...famousPeoplePendingApprovalRoutes)
  debug(`site: ${site || 'not set'}`)
  const pathname = (window?.location?.pathname || '').replace(/\/$/, '')
  const isAuth = pathname.includes('/auth/')
  const isZCMS = ZION_CMS_SITES.includes(site)
  return (
    <>
      <Head>
        <link rel="preconnect" href={appConfig.BRIGHTSPOT_URL} />
        <link rel="preconnect" href="https://assets.ldscdn.org" />
        <link rel="preconnect" href="https://foundry.familysearch.org" />
        <link rel="preconnect" href="https://consent.trustarc.com" />
      </Head>
      <ErrorBoundary>
        {/* Only display MediaBanners on NLIHPs and not sub-pages */}
        {site && NLIHPS[site] && pathname.endsWith(`/${site}`) && (
          <Suspense fallback={<NoticeLoading />}>
            <MediaBanners />
          </Suspense>
        )}
        <Suspense fallback={<NoticeLoading />}>
          <Switch>
            {/* /eurona/pages routes */}
            <Route path="/affiliates/home" component={AffiliateHomePage} />

            {site === 'affiliates' && pathname.endsWith('/about') && (
              <Route path="" component={AffiliatesAboutPage} exact />
            )}

            {/* Remove these routes once there is an acceptable amount of low traffic to them */}

            <Route path="/olympics/events" component={EventsPage} />
            <Route path="/olympics" component={OlympicsPage} />
            <Route path="/bookfair" component={BookfairPage} />
            <Route
              exact
              path={`/related-to/:personKey(${activeFamousPeopleRoutes.join('|')})`}
              component={FamousPersonPage}
            />
            <Route
              path={`/related-to/:personKey(${activeFamousPeopleRoutes.join('|')})/share/:shareId`}
              component={FamousPersonPage}
            />

            {/* Sub-pages will still be rendered by Zion CMS */}

            {site === 'deutschland' && pathname.endsWith('/deutschland') && (
              <Route path="" component={DeutschlandPage} />
            )}

            {site === 'portugal' && pathname.endsWith('/portugal') && <Route path="" component={PortugalPage} />}

            {site === 'united-kingdom' && pathname.endsWith('/united-kingdom') && (
              <Route path="" component={UnitedKingdomPage} />
            )}

            {site === 'united-states' && pathname.endsWith('/united-states') && (
              <Route path="" component={UnitedStatesPage} />
            )}

            {site === 'canada' && pathname.endsWith('/canada') && <Route path="" component={CanadaPage} />}

            {/* Handle any supported Brightspot pages here */}
            {isZCMS && isAuth && (
              <AuthRoute path={`${targetEnv === 'local' ? pathname.split('/auth')[0] : ''}/auth`}>
                <BrightspotRouting site={site} />
              </AuthRoute>
            )}
            {isZCMS && !isAuth && <BrightspotRouting site={site} />}

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  )
}
export default App
